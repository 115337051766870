
import { IAttribute } from "@/types/attribute.types";
import { ICharacterHook } from '@/types/character.types';
import { defineComponent } from "vue";

const Atributos = defineComponent({
  name: "Atributos",

  props: {
    hook: {
      type: Object as () => ICharacterHook,
      required: true,
    },
  },

  setup(props) {
    const { hook } = props;
    const { computedCharacter } = hook;

    const increment = (atb: IAttribute) =>
      hook.setAttribute({ attribute: atb, value: atb.value + 1 });

    const decrement = (atb: IAttribute) =>
      hook.setAttribute({ attribute: atb, value: atb.value - 1 });

    return { increment, decrement, computedCharacter };
  },
});

export default Atributos;
