
import { defineComponent } from 'vue';


export default defineComponent({
  name: "Magias",

  setup() {
    return {}
  }
})
