
import { ICharacterHook } from '@/types/character.types';
import { IComputedClasse } from '@/types/classes.types';
import { defineComponent, h } from 'vue';
import poderArcanista from './PoderArcanista.vue';
import altaArcana from './AltaArcana.vue';
import caminhoArcanista from './CaminhoArcanista.vue';
import magiasArcanas from "./MagiasArcanas.vue";

const levelsComponents = [
  {
    level: 1,
    components: [caminhoArcanista, magiasArcanas],
  },
  {
    level: 2,
    components: [poderArcanista],
  },
  {
    level: 3,
    components: [poderArcanista],
  },
  {
    level: 4,
    components: [poderArcanista],
  },
  {
    level: 5,
    components: [magiasArcanas, poderArcanista],
  },
  {
    level: 20,
    components: [altaArcana, poderArcanista],
  }
];

export default defineComponent({
  name: "ArcanistComponent",

  props: {
    hook: {
      type: Object as () => ICharacterHook,
      required: true,
    },
    classe: {
      type: Object as () => IComputedClasse,
      required: true,
    }
  },

  setup(props) {
    return { levelsComponents }
  }
})
