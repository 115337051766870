
import races from "@/states/races";
import { ICharacterHook } from '@/types/character.types';

import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "Raca",

  props: {
    hook: {
      type: Object as () => ICharacterHook,
      required: true,
    },
  },

  setup(props) {
    const { hook } = props;
    const { computedCharacter, baseCharacter, setRace } = hook;

    const controlRace = computed({
      get: () => baseCharacter.race?.name,
      set: (name) => setRace(races.find((r) => r.name === name)),
    });

    return { controlRace, hook, races, computedCharacter };
  },
});
