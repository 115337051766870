
import { defineComponent } from "vue";

export default defineComponent({
  name: "PoderArcanista",

  setup() {
    return () => <p>Poder Arcanista Works</p>;
  },
});
