
import characterHook from "./character.store";
import Atributos from "./components/Atributos.vue";
import Raca from "./components/Raca.vue";
import Classes from "./components/Classes.vue";
import Origem from "./components/Origem.vue";
import Pericias from "./components/Pericias.vue";
import Itens from './components/Itens.vue';

export default {
  components: {
    Atributos,
    Raca,
    Classes,
    Origem,
    Pericias,
    Itens,
  },

  setup() {
    return {
      characterHook,
    };
  },
};
