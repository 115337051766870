<template>
  <p>Magias Works!</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: "Magias",

  setup() {
    return {}
  }
})
</script>
