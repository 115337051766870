
import classes from "@/states/classes";

import { defineComponent, ref, computed, reactive, readonly } from "vue";
import { IComputedClasse, Levels } from "@/types/classes.types";
import BaseClasse from "./classes/BaseClasse.vue";
import { ICharacterHook } from "@/types/character.types";

export default defineComponent({
  name: "Classes",

  components: {
    BaseClasse
  },

  props: {
    hook: {
      type: Object as () => ICharacterHook,
      required: true
    }
  },

  setup(props) {
    const { hook } = props;
    const { computedCharacter, baseCharacter, setRace } = hook;

    interface IModalClass extends IComputedClasse {
      originalLevel: Levels;
    }

    const showModal = ref(false);
    const activeClass = ref(undefined);
    let modalClass = ref<IModalClass>({} as IModalClass);

    function newClass() {
      if (activeClass.value) {
        hook.addClass({ name: activeClass.value || "", level: 1 });
        activeClass.value = undefined;
      }
    }

    function openEditor(clss: IComputedClasse) {
      modalClass.value = { ...clss, originalLevel: clss.level };
      showModal.value = true;
    }

    function applyClass() {
      const clss = modalClass.value;
      hook.setLevelClss(clss, clss.level);
      showModal.value = false;
    }

    function isMainClass(clss: IComputedClasse) {
      return hook.baseCharacter.mainClass?.name === clss.name;
    }

    function defineClass(selected: boolean, clss: IComputedClasse) {
      const sameClass = hook.baseCharacter.mainClass?.name === clss.name;

      if (!sameClass && selected) {
        hook.setMainClass(clss);
      }

      if (sameClass && !selected) {
        hook.setMainClass(undefined);
      }
    }

    const controlModalLevel = computed({
      get: () => modalClass.value?.level,
      set: value => (modalClass.value.level = value)
    });

    function isBlockedLevel(number: number) {
      const total =
        20 -
        computedCharacter.totalLevel.value +
        (modalClass.value?.originalLevel || 0);
      // console.log(number, total, number > total);
      return number > total;
    }

    const disabledClass = (clss: IComputedClasse) =>
      computedCharacter.totalLevel.value >= 20 ||
      computedCharacter.classes.value.some(e => e.name === clss.name);

    return {
      hook,
      classes,
      activeClass,
      newClass,
      applyClass,
      disabledClass,
      computedCharacter,
      isMainClass,
      defineClass,
      showModal,
      openEditor,
      modalClass,
      isBlockedLevel,
      controlModalLevel
    };
  }
});
