
import origins from "@/states/origins";
import { ICharacterHook } from '@/types/character.types';

import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "Origem",

  props: {
    hook: {
      type: Object as () => ICharacterHook,
      required: true,
    },
  },

  setup(props) {
    const { hook } = props;
    const { computedCharacter, baseCharacter, setRace } = hook;

    const controlOrigin = computed({
      get: () => baseCharacter.origin?.name,
      set: (name) => hook.setOrigin(origins.find((o) => o.name === name)),
    });

    return {
      hook,
      origins,
      controlOrigin,
      computedCharacter,
      baseCharacter,
    };
  },
});
