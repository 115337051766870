
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Alta Arcana",

  setup() {
    return () => <p>Alta Arcana Works</p>
  }
});
