
import { defineComponent, ref } from 'vue';
import items from "@/states/items";
import { IITem } from '@/types/item.type';

export default defineComponent({
  name: "Itens",

  setup() {
    const activeItem = ref(undefined);

    function disabledItem(item: IITem) {
      // TODO implementar
      return false;
    }

    function newItem() {
        console.log(activeItem.value);
        activeItem.value = undefined;
        // TODO implementar
    }

    return {
      items,
      activeItem,
      newItem,
      disabledItem,
    }
  }
})
